@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

*{
  font-family: 'Poppins',Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

body{
  background: var(--background);
  color: var(--text);
}

.link{
  text-decoration: none;
  color: var(--link-color);
}